<template>
<div class="min-w-screen bg-gray-50 flex items-center justify-center">
    <div class="w-full bg-white border-t border-b border-gray-200 px-5 py-8 md:py-12 text-gray-800">
        <div class="w-full max-w-6xl mx-auto">
            <div>
                <h3 class="mx-auto text-center md:text-3xl max-w-xl font-bold mb-12 text-sky-900 font-nitti">Testimonials from IFAs</h3>
            </div>
            <div class="-mx-3 md:flex flex-wrap items-strech">
            <div v-for="testimonial in testimonials" :key="testimonial.id" class="px-3 md:w-1/3 min-h-max flex">
                    <div class="rounded-lg bg-white border border-gray-200 p-5 text-gray-500 mb-3">
                        <div class="w-full">
                            <p class="text-base text-gray-500">
                              <span class="text-base leading-none italic font-bold text-gray-500 mr-1">"</span>
                              {{testimonial.text}}
                              <span class="text-base leading-none italic font-bold text-gray-400 ml-1">"</span>
                            </p>
                        </div>
                        <div class="w-full flex items-center">
                            <div class="flex-grow mt-4">
                                <h6 class="font-bold text-base uppercase text-sky-900">{{testimonial.name}}<br>
                                <span class="text-s">{{testimonial.company}}</span></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>


<script> 
export default {
  data() {
    return {
      testimonials: [
       {
          id: 'test1',
          text: `I retired nearly 5 years ago and transferred my business to IFS. The transfer went smoothly, and the ongoing renewals have been paid on time each and every month.`,
          name: `Bill Geller`,
          company: `Belvedere Insurance, Tadely, Berks`
        },
        {
          id: 'test2',
          text: `IFS has greatly enhanced the investment proposition for our clients. This has been a win-win situation for all concerned.`,
          name: `John Whitehead`,
          company: `Priory Financial Ltd, Christchurch, Dorset`
        },
        {
          id: 'test3',
          text: `I have a number of very high net-worth clients, and I was concerned about losing them. Jeremy and his team have retained them all. This was no easy feat and speaks volumes of their commitment to me.`,
          name: `Trevor Cornford`,
          company: `Tiercey Financial Management, Wokingham, Berks`
        }
      ]
    }
  }
}

</script>