<template>
<div class="md:flex flex-col md:flex-row dropdown-size max-w-6xl mx-auto ">
  <div class="flex flex-col w-full text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0">
    <div class="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
      <h1 href="#" class="font-semibold tracking-widest text-sky-900 rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline font-nitti">Document Library</h1> 
    </div>
    <nav  class="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto ">
      <div v-for="item in dropdownsData" :key="item.id" @click="opendDropdown(item.id)">
       <a href="#" class="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200  hover:text-gray-900 focus:text-gray-900 hover:bg-gray-400 focus:bg-gray-400  focus:outline-none focus:shadow-outline">
        <header class="flex justify-between items-center">
            <h3 class="text-sky-900 font-nitti">
                {{item.heading}}
            </h3>
            <div class="rounded-full border-indigo w-7 h-7 flex items-center justify-center">
            <svg v-if="item.open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M3.22 9.78a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 0l4.25 4.25a.75.75 0 01-1.06 1.06L8 6.06 4.28 9.78a.75.75 0 01-1.06 0z"></path></svg>
            <svg v-if="!item.open" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M12.78 6.22a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06 0L3.22 7.28a.75.75 0 011.06-1.06L8 9.94l3.72-3.72a.75.75 0 011.06 0z"></path></svg>
            </div>
        </header>
        </a>
        <div v-if="item.open">
          <div class="pl-8 pr-8 py-5 text-grey-darkest ">
            <ul class="pl-4">
              <a v-for="paragraph in item.secondLevelData" :key="paragraph.id" target="_blank" :href="paragraph.src"> 
              <li class="">
                  <h5 class="block px-4 py-2 mt-2  font-semibold text-sky-900  py-3 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-300 focus:bg-gray-300 focus:outline-none focus:shadow-outline">{{paragraph.title}}</h5>
              </li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
</template>


<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      svgDown: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M3.22 9.78a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 0l4.25 4.25a.75.75 0 01-1.06 1.06L8 6.06 4.28 9.78a.75.75 0 01-1.06 0z"></path></svg>,
      svgUp: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M12.78 6.22a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06 0L3.22 7.28a.75.75 0 011.06-1.06L8 9.94l3.72-3.72a.75.75 0 011.06 0z"></path></svg>,
      dropdownsData: [
        {
          heading: 'Portfolio Performance',
          id: 'Performance',
          open: false,
          secondLevelData: [
            {
              id: 11,
              title: 'Adventurous',
              src: './public/Adventurous.pdf',
            },
            {
              id: 12,
              title: 'Balanced',
              src: './public/Balanced.pdf',
            },
            {
              id: 13,
              title: 'Low Risk',
              src: './public/Low-Risk.pdf'
            },
            {
              id: 14,
              title: 'Portfolio Review',
              src: './public/Review.pdf'
            }
          ]
        },
        {
          heading: 'Portfolio Construction',
          id: 'Construction',
          open: false,
          secondLevelData: [
            {
              id: 21,
              title: ' Adventurous Investment Selection',
              src: './public/Adventurous-Investment-Selection.pdf'
            },
            {
              id: 22,
              title: 'Balanced Investment Selection',
              src: './public/Balanced-Investment-Selection.pdf'
            },
            {
              id: 23,
              title: 'Low Risk Investment Selection',
              src: './public/Low-Risk-Investment-Selection.pdf'
            }
          ]
        },
        {
          heading: 'Portfolio Key Features',
          id: 'Features',
          open: false,
          secondLevelData: [
            {
              id: 31,
              title: 'Adventurous Portfolio KII',
              src: './public/Adventurous-Portfolio-KII.pdf'
            },
            {
              id: 32,
              title: 'Balanced Portfolio KII',
              src: './public/Balanced-Portfolio-KII.pdf'
            },
            {
              id: 33,
              title: 'Low Risk Portfolio KII',
              src: './public/Low-Risk-Portfolio-KII.pdf'
            }
          ]
        },
        {
          heading: 'IFS Client Agreement',
          id: 'Agreement',
          open: false,
          secondLevelData: [
            {
              id: 41,
              title: `T&C's`,
              src: './public/T&C.pdf'
            }
          ]
        }
      ]
    }
  },
  methods: {
    opendDropdown(id){
      this.dropdownsData.forEach(item => item.id === id ? item.open = !item.open : item.open = false)
    }
  }
}
</script>

<style>
.dropdown-size {
  height: 70vh;
  margin-top: 8.5rem;
  width: 100%;
}
</style>