<template>
<div class="w-full mt-16">
    <div class="flex bg-white hero-height">
        <div class="flex items-center text-center lg:text-left px-8 md:px-12  md:w-3/5 lg:w-1/2">
            <div>
                <h1 class="font-semibold text-sky-900 font-nitti">{{heroText.title}}</h1>
                <p v-for="papragraph in heroText.paragraphs" :key="papragraph.id" class="mt-2 text-base text-gray-500 ">{{papragraph.text}}</p>
            </div>
        </div>
        <div class="hidden md:block md:w-2/5 lg:w-1/2" style="clip-path:polygon(10% 0, 100% 0%, 100% 100%, 0 100%)">
            <div class="h-full object-contain" >
                <div class="h-full">
                  <img 
                  class="h-full object-contain" 
                  alt="Cover ImageName" 
                  src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2072&q=80"
                  > 
                </div>
            </div>
        </div>
    </div>
</div>
</template>:
<script>
export default {
props: {
  coverImageName: String,
  heroText: Object,
  },
  data() {
    return {
      pathToImage: [
        {
        id: 'Home',
        path: './Home.jpg'
        },
         {
        id: 'Client',
        path: './ClientHero.png'
        },
         {
        id: 'Adviser',
        path: './Adviser.jpg'
        }
      ],
    }
  },
}

</script>



<style scoped>
.hero-height {
  height: 60vh;
}


@media(min-width: 400px) {
  .hero-height {
  height: 45vh;
}
}

@media (min-width: 768px) {
  .hero-height {
  height: 45vh;
}
}



@media (min-width: 1024px) {
  .hero-height {
  height: 65vh;
}
}



</style>