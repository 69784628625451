<template>
  <div :class="{positionFixed: showLoginScreen}">
     <!-- @toggle-sign-in="toggleSignIn" :show="showLoginScreen" -->
  <Nav/>
  <!-- @close-sign-in="toggleSignIn" :state="showLoginScreen" these to signin -->
  <SignIn  />
    <HeroSection :heroText="heroSlideText" coverImageName="Home" />
    <ImageSection v-for="section in imageSections" :key="section.id" :data="section"/>

    <Footer />
  </div>
</template>

<script>
import Nav from '../../components/Nav/Nav.vue'
import SignIn from '../../components/SignIn/SignIn.vue'
import HeroSection from '../../components/HeroSection/HeroSection.vue'
import ImageSection from '../../components/ImageSection/ImageSection.vue'
import Footer from '../../components/footer/Footer.vue'


export default {
  components: {
    Nav,
    SignIn,
    HeroSection,
    ImageSection,
    Footer,
  },
  data() {
    return {
      showLoginScreen: false,
      heroSlideText: {
        title: 'IFS Financial Management',
        paragraphs: [
          {
            id: 'par1',
            text: `IFS Financial Management Ltd provides independent financial advice to a broad
            range of businesses and private individuals throughout the South of England.`
          },
          {
            id: 'par2',
            text: `We specialise in the management of pension and investments, whilst providing
            practical solutions to wider taxation and estate planning issues.`
          }
        ]
      },
      imageSections: [
        {
          id: 11,
          sideLeft: true,
          heading: `Active Support`,
          imgSrc: 'https://images.unsplash.com/photo-1554224154-26032ffc0d07?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2026&q=80',
          imgAlt: 'Ladder image',
          headingText: `Rather than being a passive bystander, we engage in regular ongoing dialogue with
our clients in order to keep them abreast of market developments and to ensure that
their portfolios are altered as conditions dictate.`,
          headingBulletPoints: [
            `At IFS we regularly monitor our clients’ holdings in order to ensure they remain suited to their objectives over the longer-term.`, 
            ],
          isLast: false,
        },
         {
          id: 22,
          sideLeft: false,
          heading: `Our Commitment to Clients`,
          headingText: '',
           imgSrc: 'https://images.unsplash.com/photo-1544377193-33dcf4d68fb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80',
          imgAlt: 'Commitment image',
          headingBulletPoints: [
            `•  We have a diverse business of clients, but all can expect the same high levels of service`,
            `•	We retain an independent approach`, 
            `•	Our advice is bespoke to the individual`, 
            `•	Clients obtain detailed quarterly valuations and regular market commentary`,
            `•	We ensure that investment decisions remain tax-efficient`],
          isLast: true,
        },
      ]
    }
  },
  methods: {
      toggleSignIn(){
        this.showLoginScreen = !this.showLoginScreen
      }
    },
}
</script>

<style scoped>
.positionFixed {
  position: fixed;
}
</style>