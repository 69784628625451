<template>
   <article @click="isShowContent" class="border-b">
      <div>
       <div class="cursor-pointer block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-gray-200  hover:text-gray-900 focus:text-gray-900 hover:bg-gray-400 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
        <header class="flex justify-between items-center">
            <h3 class="text-sky-900 font-nitti">
                {{title}}
            </h3>
            <div class="rounded-full border-indigo w-7 h-7 flex items-center justify-center">
                <svg v-if="isExpanded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M3.22 9.78a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 0l4.25 4.25a.75.75 0 01-1.06 1.06L8 6.06 4.28 9.78a.75.75 0 01-1.06 0z"></path></svg>
                <svg v-if="!isExpanded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16"><path fill-rule="evenodd" d="M12.78 6.22a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06 0L3.22 7.28a.75.75 0 011.06-1.06L8 9.94l3.72-3.72a.75.75 0 011.06 0z"></path></svg>
            </div>
        </header>
        </div>
        <div v-if="isExpanded">
          <div  class="pl-8 pr-8 py-5 text-grey-500 ">
                  <ul class="pl-4">
                      <li v-for="(paragraph, index) in paragraphs" :key="index" class="py-3 text-base text-gray-500">
                          {{paragraph.text}}
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </article>
</template>


<script>
export default {
  props: {
    id: Number,
    title: String,
    paragraphs: Array,
  },
  data() {
    return {
      isExpanded: false,
    }
  },
  methods: {
    isShowContent(){
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>

<style scoped>
.section {
  overflow:hidden;
  transition:transform 0.3s ease-out;
  height: auto;
  transform:scaleY(1);
  transform-origin: top; 
}
.section.collapsed {
  transform:scaleY(0);
}

</style>