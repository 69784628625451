<template>

<div v-if="!show && !state" class="flex flex-wrap place-items-center fixed w-full z-10 top-0 bg-white">
  <section class="relative mx-auto">
      <!-- navbar -->
    <nav class="flex justify-between text-white w-screen">
      <div class="px-5 xl:px-12 py-6 flex w-full items-center">
        <a class="text-1xl font-bold font-heading text-sky-900" href="/">
          <img class="h-12" src="./ifs-logo.svg" alt="logo">
        </a>
        <!-- Nav Links -->
        <ul class="hidden lg:flex px-4 mx-auto font-heading space-x-12">
          <li><a class="text-sky-900 hover:text-gray-600 text-lg" href="/client">Client Zone</a></li>
          <li><a class="text-sky-900 hover:text-gray-600 text-lg" href="/adviser">Adviser</a></li>
          <li><a class="text-sky-900 hover:text-gray-600 text-lg" href="/documents">Document Library</a></li>
        </ul>
        <!-- Header Icons -->
        <div class="hidden lg:flex items-center space-x-5 items-center">      
          <div class="flex flex-col">
            <a href="tel:44-01722-600-100"> 
          <p class="items-center text-sm text-sky-900">0330 088 4221</p>
          </a>
          <a href="mailto:info@ifs-online.co.uk">
          <p class="items-center text-sm text-sky-900">info@ifs-online.co.uk</p>
          </a>
          </div>
          <!-- @click="emitShowSignin" add later to login  -->
					<button class="flex items-center px-4 py-3 bg-sky-800 text-white text-lg font-semibold hover:bg-gray-500 focus:bg-gray-500">Login</button>
        </div>
      </div>
      <div class="navbar-burger self-center relative mr-3 lg:hidden text-sky-900">
        <nav>
            <button class="text-sky-900 w-10 h-10 focus:outline-none bg-white" @click="toggleMobileNav">
                <span class="sr-only">Open main menu</span>
                <div class=" block w-5 mr-5 lg:hidden left-1/2 top-1/2 pl-3 transform  -translate-x-1/2 -translate-y-1/2">
                    <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-150 ease-in-out" :class="{'rotate-45': mobileNavOpen,' -translate-y-1.5': !mobileNavOpen }"></span>
                    <span aria-hidden="true" class="block absolute  h-0.5 w-5 bg-current   transform transition duration-150 ease-in-out" :class="{'opacity-0': mobileNavOpen }"></span>
                    <span aria-hidden="true" class="block absolute  h-0.5 w-5 bg-current transform  transition duration-150 ease-in-out" :class="{'-rotate-45': mobileNavOpen, ' translate-y-1.5': !mobileNavOpen}"></span>
                </div>
            </button>
        </nav>
      <div>
	</div>
	</div>
</nav>
  </section>
</div>
        <!-- Mobile Nav -->
<ul v-if="mobileNavOpen && !state" class="flex mt-8 pt-24 flex-col w-full h-full bg-white text-black-600 text-center z-10 fixed top-12 sm:px-3 sm:py-2  md:px-8 md:py-4 overflow-y-auto">

  <li class="w-full py-4 hover:bg-gray-200"><a class="w-4/5 block m-auto text-sky-900 hover:text-gray-900 flex justify-between" href="/client">Client Zone
	<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24" height="24">
 <path fill-rule="evenodd" d="M6.22 3.22a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06L9.94 8 6.22 4.28a.75.75 0 010-1.06z"></path>
 </svg>
 </span></a></li>
  <li class="w-full py-4 hover:bg-gray-200"><a class="w-4/5 block m-auto text-sky-900 hover:text-gray-900 flex justify-between" href="/adviser">Adviser
	<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24" height="24">
 <path fill-rule="evenodd" d="M6.22 3.22a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06L9.94 8 6.22 4.28a.75.75 0 010-1.06z"></path>
 </svg>
 </span></a></li>
  <li class="w-full py-4 hover:bg-gray-200"><a class="w-4/5 block m-auto text-sky-900 hover:text-gray-900 flex justify-between" href="/documents">Document Library
	<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="24" height="24">
 <path fill-rule="evenodd" d="M6.22 3.22a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06L9.94 8 6.22 4.28a.75.75 0 010-1.06z"></path>
 </svg>
 </span></a></li>
<li class="w-full py-4 border-t border-b">
  <!-- @click="toggleLoginScreenMobile" -->
<button  class="w-4/5 block m-auto px-4 py-3 bg-sky-900 text-white text-m font-semibold hover:bg-gray-500 focus:bg-gray-500">Login</button>
</li>
 <li class="w-full py-4 hover:bg-gray-200">
   <a href="tel:44-01722-600-100"> 
   <div class="w-4/5 block m-auto text-sky-900 hover:text-gray-900 flex justify-between" >Tel: 0330 088 4221
</div>
   </a></li>
  <li class="w-full py-4 hover:bg-gray-200">
    <a href="mailto:info@ifs-online.co.uk">
    <div class="w-4/5 block m-auto text-sky-900 hover:text-gray-900 flex justify-between" >Mail: info@ifs-online.co.uk
</div>
</a>
</li>
</ul>

<div v-if="state" :class="{positionFixed: state}" class="flex flex-col h-screen z-100 inset-0 overflow-y-auto overscroll-contain bg-gray-200">
<!-- @click="toggleLoginScreenMobile" -->
<button  type="button" class="absolute right-0 w-20 h-20 hover:text-sky-800 flex flex-col justify-center">
              <svg class="self-center h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
  </button>
  <div class="grid place-items-center mx-2 my-20 sm:my-auto">
    <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
            px-6 py-10 sm:px-10 sm:py-6 
            bg-white rounded-lg shadow-md lg:shadow-lg">
      <!-- <h2 class="text-center font-semibold text-3xl lg:text-4xl text-gray-800">Login</h2> -->
      <form class="mt-10" method="POST">
        <label for="email" class="block text-xs font-semibold text-gray-600 uppercase">E-mail</label>
        <input id="email" type="email" name="email" placeholder="e-mail address" autocomplete="email"
                    class="block w-full py-3 px-1 mt-2 
                    text-gray-800 appearance-none 
                    border-b-2 border-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
                    required />
        <label for="password" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Password</label>
        <input id="password" type="password" name="password" placeholder="password" autocomplete="current-password"
                    class="block w-full py-3 px-1 mt-2 mb-4
                    text-gray-800 appearance-none 
                    border-b-2 border-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
                    required />
          <button type="submit"
                    class="w-full py-3 mt-10 bg-sky-900 rounded-sm
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-gray-500 focus:bg-gray-500 hover:shadow-none">
                    Login</button>
                    <!-- @click="toggleLoginScreenMobile" add to cancel -->
                    <button type="submit" 
                    class="w-full py-3 mt-5 bg-sky-900 rounded-sm
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-gray-500 focus:bg-gray-500 hover:shadow-none">
                    Cancel</button>
                
            </form>
        </div>
    </div>
</div>


</template>

<script>


export default {

  props: {
    show: Boolean,
  },
  data(){ 
    return {
      mobileNavOpen: false,
      state: false,
      companyName: 'Ifs Financial Management',
      mobileNavigation: [ // decide how to render these, add some kind of transition?
        { name: 'Dashboard', href: '#', current: true },
        { name: 'Team', href: '#', current: false },
        { name: 'Projects', href: '#', current: false },
        { name: 'Calendar', href: '#', current: false },
      ] 
    }
  },
  methods: {
    toggleMobileNav(){
      this.mobileNavOpen = !this.mobileNavOpen;
      const body = document.querySelector('body');
      if(this.mobileNavOpen){
        body.classList.add('overflow-hidden');
      } else {
        body.classList.remove('overflow-hidden');
      }
    },
    emitShowSignin(){
      this.$emit('toggle-sign-in')
    },
    toggleLoginScreenMobile() {
      this.state = !this.state
    }
  }
}
</script>

<style scoped>
.positionFixed {
  position: fixed;
  width: 100%;
}
</style>