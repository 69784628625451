import { createRouter, createWebHistory } from 'vue-router'
import Home from '../Pages/Landing/HomeLanding.vue'
import Documents from '../views/Documents.vue'
import Client from '../views/Client.vue'
import Adviser from '../views/Adviser.vue'
import NotFoundComponent from '../views/404.vue'
import MyMap from '../views/Map.vue'
import SignIn from '../components/SignIn/SignIn.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/client',
    name: 'Client',
    component: Client
  },
  {
    path: '/adviser',
    name: 'Adviser',
    component: Adviser
  },
  { 
    path: '/:pathMatch(.*)',
   component: NotFoundComponent 
   },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/map',
    name: 'Map',
    component: MyMap
  },
  {
    path: '/login',
    name: 'Login',
    component: SignIn
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
