<template>
<div :class="{positionFixed: showLoginScreen}">
  <!-- @toggle-sign-in="toggleSignIn" :show="showLoginScreen"  -->
  <Nav />
  <!-- @close-sign-in="toggleSignIn" :state="showLoginScreen" -->
  <SignIn  />
  <HeroSection :heroText="heroSlideText" coverImageName="Adviser"/>
  <Accordion :accordions="accordions" />
  <Testimonials />
  <Footer />
  </div>
</template>


<script>
import Nav from '../components/Nav/Nav.vue'
import SignIn from '../components/SignIn/SignIn.vue'
import HeroSection from '../components/HeroSection/HeroSection.vue'
import Accordion from '../components/Accordion/Accordion.vue'
import Testimonials from '../components/Testimonials/Testimonials.vue'
import Footer from '../components/footer/Footer.vue'
export default {
  components: {
    Nav,
    SignIn,
    HeroSection,
    Accordion,
    Testimonials,
    Footer
  },
    data() {
    return {
      showLoginScreen: false,
      heroSlideText: {
        title: 'Corporate Acquisitions',
        paragraphs: [
          {
            id: 'par1',
            text: `IFS has been built through our acquisition of other regional IFA practices.`
          },
          {
            id: 'par2',
            text: `We have taken over a number of larger firms, but mostly, smaller one-man operations where the owner is looking to retire and obtain an income share or a capital payment for the client-book.`
          },
          {
            id: 'par3',
            text: `We make due-diligence a doddle and take care of the entire novation process.`
          },
          {
            id: 'par4',
            text: `All we require is provision of your clients' policy records, in whatever format you can provide.`
          }
        ]
      },
      accordions: [
      {
        id: 1,
        title: 'Our Acquisition Strategy',
        paragraphs: 
        [
          {text: `IFS has been built entirely through the acquisition of other retiring IFAs – our first takeover being completed in 2003.
          Since then we have taken over 18 other practices, both large and small, and now manage in excess of £130 million of client funds.`},
          {text: `We offer both an immediate buy-out option, and an ongoing revenue-share agreement – where we typically pay 70:30 in your favour for five years.`},
          {text: `We are always keen to chat to advisers now approaching their own retirement.`},
        ],
      },
      {
        id: 2,
        title: `A Win-Win`,
        paragraphs: [
          {text: `We believe our approach offers a win-win for the retiring IFA, and most importantly, their clients.`},
          {text: 'We will offer [but not force] to upgrade all clients to the latest online trading platforms, whilst comprehensively reviewing their investment mix and tax arrangements to ensure continued suitability.'},
          {text: `This leaves the client set up for the 21st Century and their former adviser, able to retire assured that their valued clients are placed in good hands.`},
        ],
      },
      { 
        id: 3,
        title: 'Protecting Your Interests',
        paragraphs: [
          {text: 'To protect your income during an acquisition, our management contract clearly stipulates that, when we replace an existing contract and is moved to a new platform it goes straight back to your agencies and not to us.'},
          {text: 'We employ a number of other measures to ensure that you are fully protected; for example, your money is paid into a separate bank account that is fully reconciled and reported monthly.'},
        ],
      },
      ]
    }
    },
    methods: {
      toggleSignIn(){
        this.showLoginScreen = !this.showLoginScreen
      }
    }
  }
</script> 

<style scoped>
.positionFixed {
  position: fixed;
}
</style>