<template>
  <footer :class="{positionBotton: custom}" class="bg-gray-50 pt-8 pb-3 z-100">
  <div class="container w-full max-w-6xl mx-auto">
    <div class="flex flex-wrap text-left lg:text-left px-5">
      <div class="w-full md:w-4/12 border-black">
        <h4 class="text-center md:text-left font-semibold text-sky-900 font-nitti">{{mainMessageHeading}}</h4>
          <div class="flex justify-center md:justify-start text-gray-500 pt-2">
            <img class="-ml-12 md:-ml-2 mr-4 md:mr-0" src="../HeroSection//Phone.svg"> 
            <a href="tel:44-01722-600-100"> 
            <p class=" md:ml-6 text-base self-center">0330 088 4221</p>
            </a>
          </div>
          <div class="flex justify-center md:justify-start  text-gray-500 pt-2">
            <img  src="../HeroSection/Email.svg">
            <a href="mailto:info@ifs-online.co.uk">
            <p class="pl-6 md:pl-0  md:ml-6 text-base">info@ifs-online.co.uk</p>
            </a>
          </div>
      </div>
      <div class="w-full pt-5 md:pt-0 md:w-8/12">
        <div class="flex flex-col md:flex-row items-top mb-6">
          <div class="w-full lg:w-4/12  ml-auto">
            <h4 class="text-center md:text-left block uppercase text-sky-900 text-base font-semibold font-nitti">Useful Links</h4>
            <ul class="list-unstyled">
              <FooterLink v-for="link in footerUsefulLinks" :key="link.id" :linkData="link"/>
            </ul>
          </div>
          <div class="w-full lg:w-4/12 pt-3 md:pt-0">
            <h4 class="text-center md:text-left block uppercase text-sky-900 text-base font-semibold font-nitti">Other Resources</h4>
            <ul class="list-unstyled">
              <FooterLink v-for="link in footerMoreInfoLinks"  :key="link.id" :linkData="link" :target="link.target"/>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-1 border-white">
    <div class="flex flex-wrap items-center md:justify-between ">
      <div class="w-full  px-4 mx-auto text-center">
        <div class="text-xs text-gray-500 font-semibold py-1">
        <a href="https://wfma.agency" target="_blank">
          <span class="text-gray-500 hover:text-sky-900">Powered by WFMA</span>
         </a>
         © <span id="get-current-year">{{currentDate}}</span>
         <a href="https://www.fca.org.uk/firms/financial-services-register" target="_blank">
         <p class="text-gray-500 hover:text-sky-900">{{footerInfo.text}}<br>{{footerInfo.fca}}
         </p>
         </a>
         
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
// rename to general Link?
import FooterLink from './FooterLink.vue'

export default {
  components: {
    FooterLink,
  },
  data() {
    return {
      footerInfo: {
        text: `IFS Financial Management Ltd is authorised & regulated by the Financial Conduct Authority.`,
        fca: `FCA Ref: 225384`
      },
      mainMessageHeading: `Get in touch`,
      mainMessageText: `Find us on any of these platforms, we respond 1-2 business days.`,
      footerUsefulLinks: [{
        id: 1,
        href: '/map',
        name: 'Map to our office',
        target: ''
      },
      {
        id: 2,
        href: 'https://register.fca.org.uk/s/',
        name: 'FCA Register',
        target: '_blank'
      }],
    footerMoreInfoLinks: [{
      id: 5,
        href: 'https://www.trustnet.com/',
        name: 'Trustnet',
        target: '_blank'
      },
      {
        id: 6,
        href: 'https://www.transact-online.co.uk/',
        name: 'Transact',
        target: '_blank'
      },
      {
        id: 7,
        href: 'https://www.intelliflo.com/uk/who-we-are',
        name: 'Intellifo',
        target: '_blank'
      }]
    }
  },
    computed: {
      currentDate() {
        return new Date().getFullYear();
      }
    }
}
</script>

<style scoped>


@media screen and (min-width: 600px) {
  .positionBotton {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
}
</style>