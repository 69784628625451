<template>
  <div :class="{positionFixed: showLoginScreen}">
    <!-- @toggle-sign-in="toggleSignIn" :show="showLoginScreen" -->
  <Nav />
  <!-- @close-sign-in="toggleSignIn" :state="showLoginScreen" -->
  <SignIn  />
  <Dropdowns />
  <Footer  />
  </div>
</template>


<script>
import Nav from '../components/Nav/Nav.vue'
import SignIn from '../components/SignIn/SignIn.vue'
import Dropdowns from '../components/Dropdowns/Dropdowns.vue'
import Footer from '../components/footer/Footer.vue'
export default {
  components: {
    Nav,
    SignIn,
    Dropdowns,
    Footer,
  },
  data(){
    return {
      showLoginScreen: false,
    }
  },
   methods: {
      toggleSignIn(){
        this.showLoginScreen = !this.showLoginScreen
      }
    },
}
</script> 

<style scoped>
.positionFixed {
  position: fixed;
  width: 100%;
}
</style>