<template>
  <div :class="{positionFixed: showLoginScreen}">
    <!-- nav - @toggle-sign-in="toggleSignIn" :show="showLoginScreen" -->
  <Nav />
  <!-- @close-sign-in="toggleSignIn" :state="showLoginScreen" -->
  <SignIn  />
  <HeroSection :heroText="heroSlideText" coverImageName="Client"/>
  <Accordion :accordions="accordions" />
  <Footer />
  </div>
</template>


<script>
import Nav from '../components/Nav/Nav.vue'
import HeroSection from '../components/HeroSection/HeroSection.vue'
import Accordion from '../components/Accordion/Accordion.vue'
import Footer from '../components/footer/Footer.vue'
import SignIn from '../components/SignIn/SignIn.vue'
export default {
  components: {
    Nav,
    HeroSection,
    Accordion,
    Footer,
    SignIn
  },
    data() {
    return {
      showLoginScreen: false,
      heroSlideText: {
        title: 'Advice Across Generations',
        paragraphs: [
          {
            id: 'par1',
            text: `Our business focuses on building longer-term client relationships and today over 90% of our revenue comes from existing clients.`
          },
          {
            id: 'par2',
            text: `We do not follow fads, nor do we put our clients in unregulated investments; instead, we undertake thorough research before we recommend any investment and consider the longer-term suitability of our recommendations on a broader level.`
          }
        ]
      },
      accordions: [
        { 
        id: 1,
        title: 'Advice Across Generations',
        paragraphs: [
          {text: 'Our business focuses on building longer-term client relationships and today over 90% of our revenue comes from existing clients.'},
          {text: 'We do not follow fads, nor do we put our clients in unregulated investments; instead we undertake thorough research before we recommend any investment and consider the longer-term suitability of our recommendations on a broader level.'},
        ],
      },
       {
        id: 2,
        title: `Seeing the "Wood from the Trees`,
        paragraphs: [
          {text: `At IFS we design investment strategies that take into account current world events and the general economic climate. Our approach is active.`},
          {text: 'We then provide ongoing advice so that our clients can adapt their portfolios as we move through the economic cycle.'},
          {text: 'The consideration of tax issues remains paramount at all times.'},
        ],
      },
      {
        id: 3,
        title: 'Ongoing Risk and Tax Management',
        paragraphs: [
          {text: 'The starting point in our investment process are a series of "template" portfolios that enable our clients to move up or down the risk/reward scale.'},
          {text: `These template portfolios are then be "tweaked" to a more bespoke level for each individual client to ensure that the fit their exact requirements.`},
          {text: 'Over time we make intuitive decisions as to how the asset mix should change, which we convey to clients in an easy-to-understand format.'},
        ],
      },
      { 
        id: 4,
        title: 'Strategic Thinking',
        paragraphs: [
          {text: 'Investment opportunities shift quickly. We invest heavily in our back-office technology to ensure that we remain agile in our response.'},
        ],
      },
      ]
    }
    },
    methods: {
      toggleSignIn(){
        this.showLoginScreen = !this.showLoginScreen
      }
    },
}
</script> 

<style scoped>
.positionFixed {
  position: fixed;
}
</style>