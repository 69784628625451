<template>
<div >
<!-- :class="{positionFixed: showLoginScreen}" -->
  <!-- @toggle-sign-in="toggleSignIn" :show="showLoginScreen" -->
  <Nav />
  <!-- @close-sign-in="toggleSignIn" :state="showLoginScreen" -->
  <SignIn  />
  <div class="map-size mt-5" ref="mapDiv"></div>
  <div class="address w-full max-w-6xl mx-auto my-10 flex flex-col px-5">
    <h3 class="text-xl fonat-semibold text-sky-900">Address</h3>
    <p class="mt-5 text-gray-500 text-base">IFS Financial Management Ltd</p>
    <p class="mt-5 text-gray-500 text-base">Suite 210,</p>
    <p class="text-gray-500 text-base">95 Mortimer Street,</p>
    <p class="text-gray-500 text-base">London,</p>
    <p class="text-gray-500 text-base">W1W 7GB</p>

  </div>
  <Footer />
  </div>
</template>

<script>

import { onMounted, ref } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import Nav from '../components/Nav/Nav.vue'
import Footer from '../components/footer/Footer.vue'
import SignIn from '../components/SignIn/SignIn.vue'
export default {
  components: {
    Nav, 
    Footer, 
    SignIn,
  },
  setup() {
    
    const centerPoint = {
      lat: 51.51747509726373,
      lng: -0.14206946179358226
    }
    const mapDiv = ref(null)
    const loader = new Loader({apiKey: process.env.VUE_APP_API_KEY})
    
    
    onMounted(async () => {
      let map = await loader.load().then(() => new window.google.maps.Map(mapDiv.value, {
        center: centerPoint,
        
        zoom: 12,
      }))

      const marker = new window.google.maps.Marker({
        position: centerPoint,
      })
      marker.setMap(map)

      // const toggleSignIn = ( ) => {
      //   this.showLoginScreen = !this.showLoginScreen
      // }
      
    })
    return  {centerPoint, mapDiv}
  },

}
</script>

<style>
.map-size {
  height: 60vh;
  width: 100%;
}

.positionFixed {
  position: fixed;
}
/* .address {
  height: 20vh;
  width: 100%;
 
} */

</style>