<template>
<div v-if="state" class="flex flex-col h-screen z-100 inset-0 overflow-y-auto overscroll-contain bg-gray-200">
<!-- @click="emitCloseSignIn" -->
<button  type="button" class="absolute right-0 w-20 h-20 hover:text-sky-800 flex flex-col justify-center">
              <svg class="self-center h-6 w-6 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
  </button>
  <div class="grid place-items-center mx-2 my-20 sm:my-auto">
    <div class="w-11/12 p-12 sm:w-8/12 md:w-6/12 lg:w-5/12 2xl:w-4/12 
            px-6 py-10 sm:px-10 sm:py-6 
            bg-white rounded-lg shadow-md lg:shadow-lg">
      <form class="mt-10" method="POST">
        <label for="email" class="block text-xs font-semibold text-gray-600 uppercase">E-mail</label>
        <input id="email" type="email" name="email" placeholder="E-mail address" autocomplete="email"
                    class="block w-full py-3 px-1 mt-2 
                    text-gray-800 appearance-none 
                    border-b-2 border-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
                    required />
        <label for="password" class="block mt-2 text-xs font-semibold text-gray-600 uppercase">Password</label>
        <input id="password" type="password" name="password" placeholder="Password" autocomplete="current-password"
                    class="block w-full py-3 px-1 mt-2 mb-4
                    text-gray-800 appearance-none 
                    border-b-2 border-gray-100
                    focus:text-gray-500 focus:outline-none focus:border-gray-200"
                    required />
          <button type="submit"
                    class="w-full py-3 mt-10 bg-gray-800 rounded-sm
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-sky-800 focus:bg-sky-800 hover:shadow-none">
                    Login</button>
                    <!-- @click="emitCloseSignIn" -->
                    <button type="submit" 
                    class="w-full py-3 mt-5 bg-gray-800 rounded-sm
                    font-medium text-white uppercase
                    focus:outline-none hover:bg-sky-800 focus:bg-sky-800 hover:shadow-none">
                    Cancel</button>
            </form>
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    state: Boolean
  },
  methods: {
    emitCloseSignIn() {
      this.$emit('close-sign-in')
    }
  }
}
</script>