<template>
  <li class="hover:bg-gray-200 cursor-pointer">
    <a class="text-center md:text-left text-gray-500 hover:text-sky-900 font-semibold block pt-2 text-sm" :href="linkData.href" :target="linkData.target">{{linkData.name}}</a>
  </li>
</template>

<script>
export default {
  props: {
    linkData: {},
  }
}
</script>