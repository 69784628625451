<template>
  <section class="relative bg-blueGray-50 mt-8" >
<div class="items-center flex flex-wrap">
  <div v-if="data?.sideLeft" class="w-full md:w-6/12 mr-auto">
    <img :alt="data.imgAlt" class="img-big max-w-full max-height shadow-lg" :src="data.imgSrc">
  </div>
  <div class="w-full md:w-6/12 ml-auto mr-auto py-5 px-4">
    <div class="">
      <h2 class="font-semibold text-sky-900 font-nitti">{{data.heading}}</h2>
      <p class="mt-4 text-base leading-relaxed text-gray-500">
        {{data.headingText}}
      </p>
      <ul class="list-none mt-6">
        
        <li v-for="(item, index) in data.headingBulletPoints" :key="index" class="py-2"> 
          <div class="flex items-center">
              <p class="text-base text-gray-500">
               {{item}}
              </p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="!data?.sideLeft" class="w-full md:w-6/12 order-first md:order-last" >
    <!-- <img :alt="data.imgAlt" class="img-big max-w-full max-height shadow-lg" :src="require(`@/assets/${data.imgSrc}`)"> -->
    <img :alt="data.imgAlt" class="img-big max-w-full max-height shadow-lg" :src="data.imgSrc">
  </div>
</div>
</section>
</template>

<script>
export default {
  props: {
    data: {},
   
  },
  computed: {
    getImageSrc(){
    return this.data.imgSrc
    }
  },
  methods: {
    getSrc(url) {
      console.log(url)
      return url
    }
  }
}
</script>


<style scoped>

.img-big { /* apply this only on big screen */
    width: 100%;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-stretch;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
}
</style>